.headerBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.headerText {
  text-decoration: none;
  text-align: center;
  color: white;
  text-shadow: -1px 2px 0 #000,
				  1px 1px 0 #000,
				 1px -1px 0 #000;
}

/* -------------------  Nav Bar  --------------------------- */

#nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  border: 1px solid #e7e7e7;
  background: rgba(255,255,255,.5);
  min-width: 100%;
}

.navBarLogin li, .navBarLogout li {
  float: right;
}

.navbar {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 20px;
  text-decoration: none;
}

/* Change the link color to #111 (black) on hover */
.navbar:hover {
  background-color: #111;
}
