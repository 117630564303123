.loginBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  padding: 10px 10px 25px 10px;
  background-color: #f3f3f3;
  border-radius: 10px;
}

.loginForm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  min-height: 150px;
  margin-top: 15px;
}

.loginBtn {
  padding: 5px;
}

.loginBtn:hover{
  background-color: #111;
  color: white;
}

