@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@900&family=Open+Sans&family=Pacifico&display=swap);
.homePage {
  background: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url(/static/media/fresh-vegetables.cfe6309d.jpg);
  background-size: cover;
  border-radius: 10px;
}

.landingPageParent {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }

  .landingPage1 {
    color: white;
  }
  
  .landingPage1,
  .landingPage2,
  .signupForm {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10px;
  }

  /* ------------------- Sign-up Form ------------------------ */
  
  .signupForm {
      min-height: 300px;
      justify-content: space-between;
  }
  
  .formBox {
    border: 1px solid #e7e7e7;
    border-radius: 10px;
    background-color: #f3f3f3;
    padding: 15px;
    margin: 0 20px 20px 20px;
  }

  /* -------------------- Register Button ------------------ */

  .registerSubmit {
    padding: 5px;
  }

  .registerSubmit:hover{
    background-color: #111;
    color: white;
  }

  /* --------------------- Triple Boxes --------------------- */

  .container-boxes{
    margin: 0 auto;
    padding: 0;
    display:flex;
    justify-content: space-around;
    align-items: flex-end;
    color: white;
  }
  
  .box{
    background: rgba(255,255,255,.5);
    margin: 1rem;
    padding: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 180px;
    transition: all 250ms ease-out;
  }
  
  .box:hover{
    background: rgba(255,255,255,.7);
    transform: translateY(-20%);
  }
  
  .text h3{
    text-transform: uppercase;
    letter-spacing: 4px;
    margin-bottom: 0;
  }
  
  .text p{
    margin-top: 1rem;
    line-height: 1.5rem;
    text-align: left;
  }
  
  .text{
    padding: .5rem;
    color: black;
  }

  /* ---------------------Media Rules ------------------------ */

  @media only screen and (max-width: 600px) {
    .landingPageParent {
        flex-direction: column;
    }

    .container-boxes {
      flex-direction: column;
    }
  }
  
.loginBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  padding: 10px 10px 25px 10px;
  background-color: #f3f3f3;
  border-radius: 10px;
}

.loginForm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  min-height: 150px;
  margin-top: 15px;
}

.loginBtn {
  padding: 5px;
}

.loginBtn:hover{
  background-color: #111;
  color: white;
}


.dashboardPage {
  background-color: #f3f3f3;
}

.dashboardWindow {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  padding: 10px 10px 20px 10px;
}

.dSidebar {
  padding: 20px;
  align-self: stretch;
  border: 1px solid #e7e7e7;
  min-height: 100px;
}

.addItemBtn {
  padding: 5px;
}

.addItemBtn:hover{
  background-color: #111;
  color: white;
}

.titleBox {
  padding-bottom: 15px;
}

.dashboardBox {
  flex-grow: 2;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: 1px solid #e7e7e7;
}

/* ---------------------- Item List Styling ---------------- */

.resultsList {
  display: flex;
  flex-grow: 2;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  min-width: 95%;
}

.resultsList li {
  border: 1px solid #e7e7e7;
  min-width: 95%;
}

.itemCard {
  display: flex;
  flex-grow: 2;
  justify-content: space-around;
  align-items: center;
}

.itemCardLi a {
  list-style: none;
  text-decoration: none;
  color: #111;
}

.itemCard:hover {
  background-color: #111;
  color: white;
}

/* ---------------------- Expired Box ------------------------ */

.expired {
  background-color: red;
  color: white;
}


/* ------------------------- Media Rules ----------------------- */

@media only screen and (max-width: 414px) {
  .dashboardWindow {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 15px;
    padding: 0px;
    min-width: 100%;
  }

  .dashboardBox {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border: none;
    min-width: 100%;
  }

  .dSidebar {
    display: flex;
    justify-content: flex-end;
    margin-right: 15px;
    padding: 10px 0 0 0;
    min-height: 50px;
    border: none;
  }

  .addItemBtn {
    padding: 10px, 20px, 10px, 20px;
  }

  .resultsList {
    display: flex;
    flex-grow: 2;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
    min-width: 100%;
  }
  
  .resultsList li {
    border: 1px solid #e7e7e7;
    min-width: 100%;
  }
}

.additemWindow {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    padding: 10px;
  }
  
  .cancelSidebar {
    padding: 20px;
    align-self: stretch;
    border: 1px solid #e7e7e7;
    background-color: #f3f3f3;
    min-height: 100px;
  }
  
  .addItemBox {
    flex-grow: 2;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  
  .addItemForm {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    min-height: 200px;
    border: 1px solid #e7e7e7;
    border-radius: 10px;
  }

  .addCustomItemForm {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    min-height: 200px;
    border: 1px solid #e7e7e7;
    border-radius: 10px;
  }

  /* ------------------- Buttons ------------------------ */

  .backBtn, .addExistingItemForm, .addCustomItemBtn {
    padding: 5px;
  }

  .backBtn:hover, .addExistingItemForm:hover, .addCustomItemBtn:hover{
    background-color: #111;
    color: white;
  }

  /* ---------------------- Media Rules ------------------ */

  @media only screen and (max-width: 414px) {

    .additemWindow {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 15px;
      padding: 10px;
    }

    .addItemBox {
      flex-grow: 2;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: column;
    }

    .cancelSidebar {
      display: flex;
      justify-content: flex-end;
      padding: 10px 0 0 0;
      border: none;
      background-color: #f3f3f3;
      min-height: 50px;
    }

    .backBtn {
      padding: 10px 15px 10px 15px;
      margin-bottom: 10px;
    }

    .addItemForm {
      padding: 20px 20px 5px 20px;
      min-height: 150px;
      min-width: 80%;
      margin-bottom: 10px;
    }

    .addExistingItemForm, .addCustomItemBtn {
      padding: 10px 15px 10px 15px;
    }

    .backBtn:hover, .addExistingItemForm:hover, .addCustomItemBtn:hover{
      background-color: none;
      color: none;
    }

  }
.headerBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.headerText {
  text-decoration: none;
  text-align: center;
  color: white;
  text-shadow: -1px 2px 0 #000,
				  1px 1px 0 #000,
				 1px -1px 0 #000;
}

/* -------------------  Nav Bar  --------------------------- */

#nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  border: 1px solid #e7e7e7;
  background: rgba(255,255,255,.5);
  min-width: 100%;
}

.navBarLogin li, .navBarLogout li {
  float: right;
}

.navbar {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 20px;
  text-decoration: none;
}

/* Change the link color to #111 (black) on hover */
.navbar:hover {
  background-color: #111;
}

.detailWindow {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  margin-top: 15px;
}

.detailBox {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  min-height: 300px;
  border: 1px solid black;
  border-radius: 10px;
  padding: 30px;
  background-color: #f3f3f3;
}

.expiredBox {
  text-align: center;
  background-color: red;
  color: white;
  margin: 0 10px 0 10px;
  padding: 8px;
  border-radius: 10px;
}

/* --------------------- Edit Item Form ------------------------ */

.editItemForm {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.editItemBtn,
.editItemCancel {
  padding: 20px;
}

.editBtn,
.deleteBtn {
  margin-top: 15px;
  padding: 10px;
}

.editItemBtn:hover,
.editItemCancel:hover, 
.editBtn:hover, 
.deleteBtn:hover {
  background-color: #111;
  color: white;
}

/* ----------------------- Count-down Box -------------------- */

.timerBox {
  display: flex;
}

.countDownBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  min-width: 100%;
}

.clockDays,
.clockHours,
.clockMinutes,
.clockSeconds {
  padding: 20px;
  border-radius: 5px;
  font-family: 'Montserrat', sans-serif;
  font-size: 3em;
  color: #102b3f;
  width: 125px;
}

.days,
.hours,
.minutes,
.seconds {
  color: white;
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
}

.clockDays {
  background-color: #ef2f3c;
}

.clockHours {
  background-color: #a2abab;
}

.clockMinutes {
  background-color: #276fbf;
}

.clockSeconds {
  background-color: #f0a202;
}

.hidden {
  display: none;
}

/* ------------------------- Media Rules --------------------- */

@media only screen and (max-width: 414px) {
  .detailWindow {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 15px;
  }

  .timerBox {
    flex-direction: column;
  }

  .detailBox {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    min-height: 80%;
    min-width: 80%;
    border: 1px solid black;
    border-radius: 10px;
    padding: 25px;
  }

  .expiredBox {
    background-color: red;
    color: white;
    margin: 0 10px 0 10px;
    padding: 8px;
    border-radius: 10px;
  }

  .countDownBox {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    min-width: 100%;
  }
}

footer {
    float: right;
    margin-right: 20px;
    font-size: 10px;
    color: white;
}
body {
  margin: 0;
  padding: 0;
  min-width: 100%;
  background-color: #102B3F;
}

h1 {
  font-size: 40px;
  font-family: 'Pacifico', cursive;
}

h2,
h3,
p {
  font-family: 'Open Sans', sans-serif;
}

.App {
  text-align: center;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

