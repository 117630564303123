.homePage {
  background: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url(../../images/fresh-vegetables.jpg);
  background-size: cover;
  border-radius: 10px;
}

.landingPageParent {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }

  .landingPage1 {
    color: white;
  }
  
  .landingPage1,
  .landingPage2,
  .signupForm {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10px;
  }

  /* ------------------- Sign-up Form ------------------------ */
  
  .signupForm {
      min-height: 300px;
      justify-content: space-between;
  }
  
  .formBox {
    border: 1px solid #e7e7e7;
    border-radius: 10px;
    background-color: #f3f3f3;
    padding: 15px;
    margin: 0 20px 20px 20px;
  }

  /* -------------------- Register Button ------------------ */

  .registerSubmit {
    padding: 5px;
  }

  .registerSubmit:hover{
    background-color: #111;
    color: white;
  }

  /* --------------------- Triple Boxes --------------------- */

  .container-boxes{
    margin: 0 auto;
    padding: 0;
    display:flex;
    justify-content: space-around;
    align-items: flex-end;
    color: white;
  }
  
  .box{
    background: rgba(255,255,255,.5);
    margin: 1rem;
    padding: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 180px;
    transition: all 250ms ease-out;
  }
  
  .box:hover{
    background: rgba(255,255,255,.7);
    transform: translateY(-20%);
  }
  
  .text h3{
    text-transform: uppercase;
    letter-spacing: 4px;
    margin-bottom: 0;
  }
  
  .text p{
    margin-top: 1rem;
    line-height: 1.5rem;
    text-align: left;
  }
  
  .text{
    padding: .5rem;
    color: black;
  }

  /* ---------------------Media Rules ------------------------ */

  @media only screen and (max-width: 600px) {
    .landingPageParent {
        flex-direction: column;
    }

    .container-boxes {
      flex-direction: column;
    }
  }
  