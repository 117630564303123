.detailWindow {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  margin-top: 15px;
}

.detailBox {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  min-height: 300px;
  border: 1px solid black;
  border-radius: 10px;
  padding: 30px;
  background-color: #f3f3f3;
}

.expiredBox {
  text-align: center;
  background-color: red;
  color: white;
  margin: 0 10px 0 10px;
  padding: 8px;
  border-radius: 10px;
}

/* --------------------- Edit Item Form ------------------------ */

.editItemForm {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.editItemBtn,
.editItemCancel {
  padding: 20px;
}

.editBtn,
.deleteBtn {
  margin-top: 15px;
  padding: 10px;
}

.editItemBtn:hover,
.editItemCancel:hover, 
.editBtn:hover, 
.deleteBtn:hover {
  background-color: #111;
  color: white;
}

/* ----------------------- Count-down Box -------------------- */

.timerBox {
  display: flex;
}

.countDownBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  min-width: 100%;
}

.clockDays,
.clockHours,
.clockMinutes,
.clockSeconds {
  padding: 20px;
  border-radius: 5px;
  font-family: 'Montserrat', sans-serif;
  font-size: 3em;
  color: #102b3f;
  width: 125px;
}

.days,
.hours,
.minutes,
.seconds {
  color: white;
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
}

.clockDays {
  background-color: #ef2f3c;
}

.clockHours {
  background-color: #a2abab;
}

.clockMinutes {
  background-color: #276fbf;
}

.clockSeconds {
  background-color: #f0a202;
}

.hidden {
  display: none;
}

/* ------------------------- Media Rules --------------------- */

@media only screen and (max-width: 414px) {
  .detailWindow {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 15px;
  }

  .timerBox {
    flex-direction: column;
  }

  .detailBox {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    min-height: 80%;
    min-width: 80%;
    border: 1px solid black;
    border-radius: 10px;
    padding: 25px;
  }

  .expiredBox {
    background-color: red;
    color: white;
    margin: 0 10px 0 10px;
    padding: 8px;
    border-radius: 10px;
  }

  .countDownBox {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    min-width: 100%;
  }
}
