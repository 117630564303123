.dashboardPage {
  background-color: #f3f3f3;
}

.dashboardWindow {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  padding: 10px 10px 20px 10px;
}

.dSidebar {
  padding: 20px;
  align-self: stretch;
  border: 1px solid #e7e7e7;
  min-height: 100px;
}

.addItemBtn {
  padding: 5px;
}

.addItemBtn:hover{
  background-color: #111;
  color: white;
}

.titleBox {
  padding-bottom: 15px;
}

.dashboardBox {
  flex-grow: 2;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: 1px solid #e7e7e7;
}

/* ---------------------- Item List Styling ---------------- */

.resultsList {
  display: flex;
  flex-grow: 2;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  min-width: 95%;
}

.resultsList li {
  border: 1px solid #e7e7e7;
  min-width: 95%;
}

.itemCard {
  display: flex;
  flex-grow: 2;
  justify-content: space-around;
  align-items: center;
}

.itemCardLi a {
  list-style: none;
  text-decoration: none;
  color: #111;
}

.itemCard:hover {
  background-color: #111;
  color: white;
}

/* ---------------------- Expired Box ------------------------ */

.expired {
  background-color: red;
  color: white;
}


/* ------------------------- Media Rules ----------------------- */

@media only screen and (max-width: 414px) {
  .dashboardWindow {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 15px;
    padding: 0px;
    min-width: 100%;
  }

  .dashboardBox {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border: none;
    min-width: 100%;
  }

  .dSidebar {
    display: flex;
    justify-content: flex-end;
    margin-right: 15px;
    padding: 10px 0 0 0;
    min-height: 50px;
    border: none;
  }

  .addItemBtn {
    padding: 10px, 20px, 10px, 20px;
  }

  .resultsList {
    display: flex;
    flex-grow: 2;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
    min-width: 100%;
  }
  
  .resultsList li {
    border: 1px solid #e7e7e7;
    min-width: 100%;
  }
}
