@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&family=Open+Sans&family=Pacifico&display=swap');

body {
  margin: 0;
  padding: 0;
  min-width: 100%;
  background-color: #102B3F;
}

h1 {
  font-size: 40px;
  font-family: 'Pacifico', cursive;
}

h2,
h3,
p {
  font-family: 'Open Sans', sans-serif;
}

.App {
  text-align: center;
}
